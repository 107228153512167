<template>
<div>
  <div id="faq" v-html="getOrganization.data.block3">
    <!-- HTML/block4 -->
  </div>
</div>
</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
import { mapGetters } from "vuex";
//import Button from './vue-bootstrap/Button.vue';

export default {
  name: "faq",
  components: {
    //OrganizationMenu
    //Button
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MAINMENU.FAQ") }]);
     //this.getOrganization();
    // console.log(this.getOrganization);
  },
  created() {

  },
  methods: {
    getOrganization() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('organizations-expanded/1', true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.organization = res.data;
          })
          .catch(() => {
            // .catch(({ response }) => {
            // context.commit(SET_ERROR, [response.data.error]);
            //console.log('errore');
            reject(0);
          });
      });
    }
  }
}
</script>

<style></style>
